import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {gsap, TimelineLite} from "gsap";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import {usePathname} from 'next/navigation';

const LoadingCounter = () => {
    const [counter, setCounter] = useState(0);
    const pathName = usePathname();
    const timelineRef = useRef(null);
    gsap.registerPlugin(DrawSVGPlugin);

    useEffect(() => {
        timelineRef.current = new TimelineLite();
        const tl = timelineRef.current;
        let currentValue = 0;
        let lastUpdateTime = Date.now();
        let isPaused = false;
        let hasReached100 = false;
        let pauseCount = 0;

        // 75% chance of a pause between 70-100%, 25% chance to go straight to 100%
        const shouldPause = Math.random() < 0.75;
        const pausePoints = shouldPause ? [Math.floor(Math.random() * 30) + 70] : [];

        const updateCounter = () => {
            const now = Date.now();
            const delta = (now - lastUpdateTime) / 1000;
            lastUpdateTime = now;

            if (!isPaused) {
                const baseSpeed = 4;
                const speedFactor = 1 - (currentValue / 150);
                const increment = (delta * 100 / (Math.random() * (3 - 1.5) + 1.5)) * speedFactor * baseSpeed;

                currentValue = Math.min(currentValue + increment, 100);
                setCounter(Math.floor(currentValue));

                // Check if counter reaches the pause point
                if (pausePoints.length > 0 &&
                    pauseCount < pausePoints.length &&
                    currentValue >= pausePoints[pauseCount] &&
                    currentValue < 100) {
                    isPaused = true;
                    pauseCount++;
                    setTimeout(() => {
                        isPaused = false;
                    }, Math.random() * 400 + 400);
                }

                // Start fade out animation when counter hits 100%
                if (currentValue >= 100 && !hasReached100) {
                    hasReached100 = true;
                    tl.fromTo('.page-change', 0.6, {
                        display: 'flex',
                        opacity: 1,
                    }, {
                        duration: 1,
                        delay: 0.2,
                        opacity: 0,
                        display: 'none'
                    });
                }
            }

            if (currentValue < 100) {
                requestAnimationFrame(updateCounter);
            }
        };

        requestAnimationFrame(updateCounter);

        return () => {
            if (timelineRef.current) {
                timelineRef.current.kill();
            }
        };
    }, [pathName]);

    return (
        <StyledComponent className={'page-change'}>
            <img src="/images/static/logo-loader.svg" alt="logo" />
            <div className={'counter'}>
                <p>{counter}%</p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh;
  position: fixed;
  flex-direction: column;
  gap: 20px;
  background-color: #F1F0EE;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #534D40;
    font-family: PP Migra;
    text-align: center;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
  }

  img {
    width: 180px;
  }

  svg {
    overflow: visible;
  }

  @media (max-width: 600px) {
    svg {
      width: 200px;
    }
  }
`;

export default LoadingCounter;

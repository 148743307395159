'use client'
import {useRef} from 'react';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {ScrollSmoother} from "gsap/dist/ScrollSmoother";
import {gsap} from "gsap";
import {usePathname} from "next/navigation";
import {useGSAP} from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, useGSAP);

export default function SmoothScroll({children}) {
    const wrapper = useRef(null);
    const content = useRef(null);
    const location = usePathname();
    const isDesktop = typeof window !== 'undefined' ? window.innerWidth > 1 : false;

    useGSAP(() => {
        if (isDesktop) {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            let smoother = ScrollSmoother.create({
                wrapper: wrapper.current,
                content: content.current,
                smooth: isSafari ? 1.5 : 2, // Use lower smooth value for Safari
                effects: true,
            });


            ScrollTrigger.refresh();

            if (location.hash) {
                setTimeout(() => {
                    smoother.scrollTo(location.hash, 3)
                }, 400);
            } else {
                window.scrollTo(0, 0);
            }
        }
    }, []);

    return (
        <div id="smooth-wrapper" ref={wrapper}>
            <div id="smooth-content" ref={content}>
                {children}
            </div>
        </div>
    );
}

import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import { useEffect, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import {usePathname} from "next/navigation";

gsap.registerPlugin(SplitText, ScrollTrigger);

export const SplitUp = () => {
    const location = usePathname();
    const isDesktop = typeof window !== 'undefined' ? window.innerWidth > 991 : false;

    useGSAP(() => {
        if (isDesktop) {
            document.fonts.ready.then(() => {
                gsap.utils.toArray(".split-up").forEach((item, i) => {
                    // Store original HTML content and letter spacing
                    const originalHTML = item.innerHTML;
                    const originalLetterSpacing = window.getComputedStyle(item).letterSpacing;

                    const parentSplit = new SplitText(item, {
                        linesClass: "split-parent",
                    });

                    new SplitText(item, {
                        type: "lines, chars",
                        linesClass: "split-child",
                    });

                    gsap.from(parentSplit.lines, {
                        duration: 1,
                        yPercent: 100,
                        // stagger: 0.1,
                        scrollTrigger: {
                            trigger: item,
                            start: "top 90%",
                            once: true
                        },
                        onComplete: () => {
                            // Remove the split-up class
                            item.classList.remove("split-up");

                            // Hide the element
                            item.style.visibility = "hidden";

                            // Revert HTML to original and apply original letter spacing
                            item.innerHTML = originalHTML;
                            item.style.letterSpacing = originalLetterSpacing;

                            // Show the element again
                            item.style.visibility = "visible";
                        }
                    });
                });
            });

            gsap.utils.toArray(".split-left").forEach((item, i) => {
                const splitText = new SplitText(item, {
                    type: "chars,words,lines",
                    reduceWhiteSpace: true,
                });
                const chars = splitText.chars;

                gsap.from(chars, 1.5, {
                    x: "10vw",
                    // opacity: 0,
                    // ease: "elastic.out(1, 0.3)",
                    stagger: 0.015,
                    scale: 0.95,
                    // delay: 0.2,
                    ease: "expo.out",
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: "restart none none reset",
                    },
                });
                gsap.from(chars, 0.6, {
                    // x: 80,
                    opacity: 0,
                    // ease: "elastic.out(1, 0.3)",
                    stagger: 0.016,
                    // delay: 0.2,
                    ease: "power4.out",
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: "restart none none reset",
                    },
                });
            });

        }
    }, [location]);

    return null; // Assuming you are not rendering anything in this component
};
// PageTransitionContext.js
import React, { createContext, useContext, useState } from 'react';

const PageTransitionContext = createContext();

export const PageTransitionProvider = ({ children }) => {
    const [overlay, setOverlay] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const value = {
        overlay,
        setOverlay,
        isLoading,
        setIsLoading,
        isInitialLoad,
        setIsInitialLoad,
    };

    return (
        <PageTransitionContext.Provider value={value}>
            {children}
        </PageTransitionContext.Provider>
    );
};

export const usePageTransition = () => {
    const context = useContext(PageTransitionContext);
    if (!context) {
        throw new Error('usePageTransition must be used within a PageTransitionProvider');
    }
    return context;
};
// components/FontPreloader.jsx
import React from 'react';

const FontPreloader = () => (
    <>

        <React.Fragment>
            <link rel="preload" href="/fonts/BananaGrotesk-Light.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Light.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Regular.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Semibold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Semibold.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Extrabold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Extrabold.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Bold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Bold.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Thin.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Thin.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Medium.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Medium.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Extralight.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/BananaGrotesk-Extralight.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/PPMigraItalic-Italic.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/PPMigraItalic-Italic.woff" as="font" type="font/woff" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/PPMigra-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/PPMigra-Regular.woff" as="font" type="font/woff" crossOrigin="anonymous" />
        </React.Fragment>


    </>
);

export default React.memo(FontPreloader);

import {gsap, Power2} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect} from "react";
import {usePathname} from "next/navigation";
import {useGSAP} from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP);

export const RevealAnimation = () => {
    const location = usePathname();
    const isDesktop = typeof window !== 'undefined' ? window.innerWidth > 991 : false;
    useGSAP(() => {
        if (isDesktop) {
            let revealContainers = document.querySelectorAll(".reveal");

            revealContainers.forEach((container) => {
                let image = container.querySelector("img,video");
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: container,
                        toggleActions: "play reverse play reverse"
                    }
                });

                tl.set(container, {autoAlpha: 1});
                tl.from(container, 1.5, {
                    xPercent: -100,
                    ease: Power2.out
                });
                tl.from(image, 1.5, {
                    xPercent: 100,
                    scale: 1.3,
                    delay: -1.5,
                    ease: Power2.out
                });
            });
        }
    }, [location]);
};

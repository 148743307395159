'use client'

import React from 'react';
import styled from "styled-components";

import {AnimatePresence, motion} from "framer-motion";
import {usePathname} from "next/navigation";

const PageWrapper = ({children}) => {
    const location = usePathname();
    return (
        <StyledComponent>
            <AnimatePresence mode="wait">
                <motion.div
                    key={location}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 1}}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

`;

export default PageWrapper;
